import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../scss/main.scss';
import * as styles from '../scss/pages/mainInfoPage.module.scss';
import Header from '../components/Header';
import SanityBlockParser from '../components/SanityBlockParser';
import MenuModal from '../components/MenuModal';
import Footer from '../components/Footer';

export default function CategoryPage({ pageContext }:any) {
  const {
    title,
    mainImage,
    body,
  } = pageContext;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen ? null : (
        <main className={styles.main}>
          <div className={styles.itemContent}>
            <h2 className={styles.h2}>{title}</h2>
            <GatsbyImage
              image={mainImage.asset.gatsbyImageData}
              alt={title}
            />
            <SanityBlockParser desc label="" sanityBlocks={body} />
          </div>
        </main>
      )}
      {menuOpen ? null : <Footer />}
      <MenuModal menuOpen={menuOpen} />
    </>
  );
}
